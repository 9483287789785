<template>
    <div class="contact">
        <h3>Kontakt</h3>
        <ul>
          <li>WhatsApp +48 720 891 444 *</li>
          <li><b style="font-size: 0.7em">*Joanna</b></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "ContactIn"
    }
</script>

<style scoped>

</style>